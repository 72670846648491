.mybtn{
    border-radius: 30px;
    background-color: #c797eb;
    border: none;
    /* padding: 10px; */
    color: white;
    padding: 10px 15px;

}
.mybtn:hover{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.bookbtn:hover{
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}
.box{
    background-image: linear-gradient(to bottom right, rgb(204, 218, 255), white);

}
.custom-btn {
  width: 130px;
  height: 40px;
  color: #fff;
  border-radius: 15px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
}

/* 1 */
.btn-1 {
  background: rgb(6,14,131);
  background: linear-gradient(0deg, rgba(6,14,131,1) 0%, rgba(12,25,180,1) 100%);
  border: none;
}
/* 8 */
.btn-8 {
  background-color: #f0ecfc;
background-image: linear-gradient(315deg, #f0ecfc 0%, #c797eb 74%);
  line-height: 42px;
  padding: 0;
  border: none;
}
.btn-8 span {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}
.btn-8:before,
.btn-8:after {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  background: #c797eb;
  /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
  transition: all 0.3s ease;
}
.btn-8:before{
   height: 0%;
   width: 2px;
}
.btn-8:after {
  width: 0%;
  height: 2px;
}
.btn-8:hover:before {
  height: 100%;
}
.btn-8:hover:after {
  width: 100%;
}
.btn-8:hover{
  background: transparent;
}
.btn-8 span:hover{
  color: #c797eb;
}
.btn-8 span:before,
.btn-8 span:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: #c797eb;
  /*box-shadow:  4px 4px 6px 0 rgba(255,255,255,.5),
              -4px -4px 6px 0 rgba(116, 125, 136, .2), 
    inset -4px -4px 6px 0 rgba(255,255,255,.5),
    inset 4px 4px 6px 0 rgba(116, 125, 136, .3);*/
  transition: all 0.3s ease;
}
.btn-8 span:before {
  width: 2px;
  height: 0%;
}
.btn-8 span:after {
  height: 2px;
  width: 0%;
}
.btn-8 span:hover:before {
  height: 100%;
}
.btn-8 span:hover:after {
  width: 100%;
}
  
  .bg{
    background-image: url("./../../public/bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }


  /* video::-webkit-media-controls-panel {
display: none !important;
opacity: 1 !important;} */

/* Add this to your Banner.css or a separate CSS file */
.video-container {
  border-color: #c797eb;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (change as needed) */
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Adjust as needed */
}

.store-buttons {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  gap: 10px; /* Spacing between buttons */
  /* align-items: center; Center-align the images */
}

.app-image {
  width: 100%; /* Allow images to take full width of the container */
  max-width: 180px; /* Limit the maximum width */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure images don't get distorted */
  margin-left: 13px;
}


