.mybtn{
    border-radius: 30px;
    background-color:blue;
    border: none;
    padding: 10px 15px;

}
.nav{
    /* height: 10vh; */
    background-color:rgb(237, 235, 255) ;
}
.nav-link{
    font-size: 15px;
}
.headlink :hover{
    border: 1px solid blue;
}
.dropdown-menu{
    background-color: rgb(62, 99, 62);
    padding: 0;
    border: none;
}
.navbar-toggler{
    width: 20%;
}
.nav-logo{
    height: 110px;

}
.faq{
    font-size: 18px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
}
.service{
    font-size: 18px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
}
@media (max-width: 768px) {
    .nav-logo{
        height: 60px;
        margin-left: 10px;
    }
  }
  @media (max-width: 768px) {
    .faq{
 
        margin-left: 90px;
    }
  }
  @media (max-width: 768px) {
    .service{
 
        margin-left: 90px;
    }
  }